import {
  DateFormatUtils,
  LoopBadge,
  LoopButton,
  Shadow,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ArrowRightEmeraldIcon,
  ArrowRightIcon,
  CdBalYellowIcon,
  DownloadIcon,
  PersonIcon,
  TableContentYellowIcon,
} from '../../../assets/images';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import Loader from '../../atoms/Spinner';
import useFetchCDAccounts from '../../pages/CDAccounts/hooks/useFetchCDAccounts';
import { isCDBalanceLow } from '../../pages/CDAccounts/utils';
import useFetchClaimList from '../../pages/ClaimsList/hooks/useFetchClaimList';
import {
  calculateInsuranceCounts,
  checkIfGPAorGTLPolicy,
  getCategorizedList,
  initialStats,
} from '../../pages/Employees/utils';
import { roasterExcelDownload } from '../Employees/ActiveRoasterTable/roasterToExcelService';
import usePolicyMembers from './hooks/usePolicyMembers';
import {
  StyledPolicyActiveRoster,
  StyledPolicyActiveRosterCDContainerRow,
  StyledPolicyActiveRosterContainer,
  StyledPolicyActiveRosterContainerColumn,
  StyledPolicyActiveRosterContainerRow,
  StyledPolicyActiveRosterContainerSeperator,
  StyledPolicyActiveRosterItem,
  StyledPolicyActiveRosterItemBody,
  StyledPolicyActiveRosterItemHeader,
  StyledPolicyActiveRosterLogo,
  StyledPolicyCDAccountEmptyContainer,
  StyledPolicyItemBodyDetails,
} from './styles';
import { IPolicyActiveRoster } from './types';
import {
  getCdAccountFromList,
  getClaimCountByStatusAndPolicyId,
} from './utils';
import { StyledFitContentWrapper } from '../EmpPolicies/styles';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../redux/slices/hrdRevampRedux';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import { isEmptyStatesEnabled } from '../../../utils/featureFlag';
import { isRealtimeTPA } from '../../../utils/common/common';
import { getClaimsLastUpdatedAt } from '../../pages/ClaimsList/utils';
import { ClaimTagEnum } from '../../../adaptars/claims/types';

const PolicyActiveRoster: React.FC<IPolicyActiveRoster> = ({
  policyDetails,
}) => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const location = useLocation();
  const pathName = extractPathName(location?.pathname);
  const selectedCompany = useSelector(selectSelectedCompany);
  const { policyMembers, isLoading: isEmpCountLoading } = usePolicyMembers(
    policyDetails.id,
  );

  const updatedStats =
    policyMembers && calculateInsuranceCounts(policyMembers, initialStats);
  const { claims: claimsList, isLoading: isClaimDataLoading } =
    useFetchClaimList();
  const isGPAorGTL = checkIfGPAorGTLPolicy(policyDetails.policyType);
  const claimsByType = isGPAorGTL
    ? {}
    : getClaimCountByStatusAndPolicyId(claimsList, policyDetails.policyNumber);

  const onholdClaimCount = claimsByType[ClaimTagEnum.Onhold]?.length || 0;
  const ongoingClaimCount = claimsByType[ClaimTagEnum.Ongoing]?.length || 0;
  const paidClaimCount = claimsByType[ClaimTagEnum.Paid]?.length || 0;
  const isClaimTrackVisible =
    (onholdClaimCount | ongoingClaimCount | paidClaimCount) !== 0;
  const { cdAccounts, isLoading: isCdAccountLoading } = useFetchCDAccounts();
  const cdAccount = getCdAccountFromList(cdAccounts, policyDetails.id);
  const isLowCdBalance = cdAccount && isCDBalanceLow(cdAccount);

  const onDownloadButtonClick = () => {
    policyMembers &&
      roasterExcelDownload(
        getCategorizedList(policyMembers),
        selectedCompany?.name,
      );
    trackClick(
      trackClickEvent(
        'DOWNLOAD_ACTIVE_ROASTER_CTA',
        location?.pathname,
        pathName,
      ),
    );
  };

  const onRosterDetailsButtonClick = () => {
    trackClick(
      trackClickEvent('VIEW_ACTIVE_ROASTER_CTA', location?.pathname, pathName),
    );
    history.push('/employees?policyId=' + policyDetails.id);
  };

  const onCdDetailsButtonClick = () => {
    trackClick(
      trackClickEvent('VIEW_CD_BALANCE_CTA', location?.pathname, pathName),
    );
    history.push('/cd-accounts#' + cdAccount?.accountNumber);
  };

  const onTrackClaimsButtonClick = () => {
    trackClick(trackClickEvent('CLAIM_CTA', location?.pathname, pathName));
    history.push('/claims?policyId=' + policyDetails.id);
  };

  const claimsLastUpdatedAt = useMemo(() => {
    const filteredClaims = claimsList.filter(
      (claimData) => claimData.policyNo === policyDetails.policyNumber,
    );
    const lastUpdatedAt = getClaimsLastUpdatedAt(filteredClaims);
    if (isEmptyStatesEnabled) {
      return isRealtimeTPA(policyDetails?.tpaName)
        ? 'Updated real time'
        : lastUpdatedAt
        ? `Last updated: ${lastUpdatedAt}. Updated every 7-10 days.`
        : '--';
    } else {
      return 'Updated real time';
    }
  }, [policyDetails, claimsList]);

  return (
    <StyledPolicyActiveRoster>
      <StyledPolicyActiveRosterItem>
        <StyledPolicyActiveRosterItemHeader>
          <StyledPolicyActiveRosterLogo src={PersonIcon} />
          <Typography variant="medium" weight="medium">
            Active Roster
          </Typography>
        </StyledPolicyActiveRosterItemHeader>
        <Shadow variant="smooth">
          <WithThemeProvider>
            <StyledPolicyActiveRosterItemBody>
              {isEmpCountLoading ? (
                <Loader />
              ) : (
                <>
                  <StyledPolicyActiveRosterContainer>
                    {!isGPAorGTL && (
                      <>
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            Total Lives
                          </Typography>
                          <Typography variant="title2" weight="medium">
                            {updatedStats?.total_lives_all ?? 0}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                        <StyledPolicyActiveRosterContainerSeperator />
                      </>
                    )}
                    <StyledPolicyActiveRosterContainerColumn>
                      <Typography variant="small" color="secondary">
                        Total Employees
                      </Typography>
                      <Typography variant="title2" weight="medium">
                        {updatedStats?.employee ?? 0}
                      </Typography>
                    </StyledPolicyActiveRosterContainerColumn>
                  </StyledPolicyActiveRosterContainer>
                  <StyledPolicyActiveRosterContainerRow>
                    {updatedStats?.total_lives_all && updatedStats?.employee ? (
                      <>
                        <LoopButton
                          onClick={onDownloadButtonClick}
                          variant="secondary"
                          textColor="emerald"
                          iconOrder="right"
                          iconSrc={DownloadIcon}
                          size="small"
                        >
                          Download
                        </LoopButton>
                        <LoopButton
                          onClick={onRosterDetailsButtonClick}
                          variant="outline"
                          iconOrder="right"
                          iconSrc={ArrowRightIcon}
                          size="small"
                        >
                          View Details
                        </LoopButton>
                      </>
                    ) : (
                      <Typography color="secondary" variant="small">
                        Active roster may take 30-35 days to update from policy
                        start date.
                      </Typography>
                    )}
                  </StyledPolicyActiveRosterContainerRow>
                </>
              )}
            </StyledPolicyActiveRosterItemBody>
          </WithThemeProvider>
        </Shadow>
      </StyledPolicyActiveRosterItem>
      <StyledPolicyActiveRosterItem>
        <StyledPolicyActiveRosterItemHeader>
          <StyledPolicyActiveRosterLogo src={CdBalYellowIcon} />
          <Typography variant="medium" weight="medium">
            Cash Deposit (CD) Balance
          </Typography>
        </StyledPolicyActiveRosterItemHeader>
        <Shadow variant="smooth">
          <WithThemeProvider>
            <StyledPolicyActiveRosterItemBody>
              {isCdAccountLoading ? (
                <Loader />
              ) : (
                <>
                  {cdAccount ? (
                    <>
                      <StyledPolicyItemBodyDetails>
                        <StyledPolicyActiveRosterCDContainerRow>
                          <StyledPolicyActiveRosterContainerColumn>
                            <Typography variant="small" color="secondary">
                              Available Balance
                            </Typography>
                            <Typography
                              variant="title2"
                              weight="medium"
                              color={isLowCdBalance ? 'planRed' : 'primary'}
                            >
                              {formatCurrencyAmount(cdAccount?.netBalance)}
                            </Typography>
                          </StyledPolicyActiveRosterContainerColumn>
                          {(!cdAccount || isLowCdBalance) && (
                            <LoopBadge
                              variant="overflow"
                              badgeType="error"
                              text={cdAccount ? 'Low' : 'Missing'}
                            />
                          )}
                        </StyledPolicyActiveRosterCDContainerRow>
                        <Typography
                          variant="extraSmall"
                          weight="regular"
                          color="secondary"
                        >
                          {`Last updated: ${
                            cdAccount?.updatedAt
                              ? new DateFormatUtils().formatDate(
                                  cdAccount.updatedAt,
                                )
                              : '--'
                          }`}
                        </Typography>
                      </StyledPolicyItemBodyDetails>
                      <StyledPolicyActiveRosterContainerRow>
                        <StyledFitContentWrapper>
                          <LoopButton
                            onClick={onCdDetailsButtonClick}
                            variant="secondary"
                            textColor="emerald"
                            iconOrder="right"
                            iconSrc={ArrowRightEmeraldIcon}
                            size="small"
                          >
                            View Details
                          </LoopButton>
                        </StyledFitContentWrapper>
                      </StyledPolicyActiveRosterContainerRow>
                    </>
                  ) : (
                    <StyledPolicyCDAccountEmptyContainer>
                      <Typography
                        color="primary"
                        variant="medium"
                        weight="semiBold"
                      >
                        Your CD Account is Being Created!
                      </Typography>
                      <Typography color="secondary" variant="small">
                        Account creation may take 30-35 days from policy start
                        date. If there is a delay contact your CSM.
                      </Typography>
                    </StyledPolicyCDAccountEmptyContainer>
                  )}
                </>
              )}
            </StyledPolicyActiveRosterItemBody>
          </WithThemeProvider>
        </Shadow>
      </StyledPolicyActiveRosterItem>
      {!isGPAorGTL && (
        <StyledPolicyActiveRosterItem>
          <StyledPolicyActiveRosterItemHeader>
            <StyledPolicyActiveRosterLogo src={TableContentYellowIcon} />

            <Typography variant="medium" weight="medium">
              Claims
            </Typography>
          </StyledPolicyActiveRosterItemHeader>
          <Shadow variant="smooth">
            <WithThemeProvider>
              <StyledPolicyActiveRosterItemBody>
                {isClaimDataLoading ? (
                  <Loader />
                ) : (
                  <>
                    <StyledPolicyItemBodyDetails>
                      <StyledPolicyActiveRosterContainer>
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            Paid
                          </Typography>
                          <Typography variant="title2" weight="medium">
                            {paidClaimCount}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                        <StyledPolicyActiveRosterContainerSeperator />
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            Ongoing
                          </Typography>
                          <Typography variant="title2" weight="medium">
                            {ongoingClaimCount}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                        <StyledPolicyActiveRosterContainerSeperator />
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            On hold
                          </Typography>
                          <Typography
                            variant="title2"
                            weight="medium"
                            color={onholdClaimCount > 0 ? 'planRed' : 'primary'}
                          >
                            {onholdClaimCount}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                      </StyledPolicyActiveRosterContainer>
                      <Typography
                        variant="extraSmall"
                        weight="regular"
                        color="secondary"
                      >
                        {claimsLastUpdatedAt}
                      </Typography>
                    </StyledPolicyItemBodyDetails>
                    {isClaimTrackVisible && (
                      <StyledPolicyActiveRosterContainerRow>
                        <StyledFitContentWrapper>
                          <LoopButton
                            onClick={onTrackClaimsButtonClick}
                            variant="secondary"
                            textColor="emerald"
                            iconOrder="right"
                            iconSrc={ArrowRightEmeraldIcon}
                            size="small"
                          >
                            Track Claims
                          </LoopButton>
                        </StyledFitContentWrapper>
                      </StyledPolicyActiveRosterContainerRow>
                    )}
                  </>
                )}
              </StyledPolicyActiveRosterItemBody>
            </WithThemeProvider>
          </Shadow>
        </StyledPolicyActiveRosterItem>
      )}
    </StyledPolicyActiveRoster>
  );
};

export default PolicyActiveRoster;
